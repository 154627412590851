
  
  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ POPUP +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

  .popupContainer {
    display: flex; 
    justify-content: center;
    width: 100%;
  }

  .popupImg {
    position: fixed;
   z-index: 1;
  }

  .popupWrapper {
    position: relative;
    display: flex; 
    justify-content: center;
    flex-direction: column;
 
    width: 100%;
    z-index: 100;
  }

  .popupButton {
    position: relative;
    margin-left: 15rem;
    min-width: 5rem;
 
  }

  

  .popupText {
    position: absolute;
    text-align: center;
    width: 40rem;
    height: 20rem;
    overflow:auto;
    margin-top: -5rem;


  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ FOLYAMAT +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

  .folyamatContainer {
    display: flex; 
 
    justify-content: center;
    align-content: center;
    position: absolute;

  }
  
  .folyamatSize{
    display: flex;
    position: relative;
     width: 90%;
    height: 55rem;
    align-self: center;

  }

  .folyamat {
    order: 2;
    display: flex; 
    justify-content: center;
    min-width: 100%;
    min-height: 50%;
    
  }

  .abra {
    display: -webkit-flex; 
    width: 96%;
    margin-left: 1%;
    justify-content: center;
  }


  .motionWrapper {
    display: flex; 

  }

  .motionPicture {
    z-index: 1;
  }

  .motionArrow {
    position: absolute;
    z-index: 2; 
    
  }

  .motionArrow:hover {
    display: none;
    position: absolute;
}

  .motionArrow:hover .motionArrowTop{
    position: relative;
    display: inline;
  }


  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ PARAM +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

  .paramContainer {
    order: 3;
    display: -webkit-flex; 
    min-width: 100%;
    min-height: 50%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    
  }

  .title h1{
    text-align: center;
    font-size: 3.5rem;
    font-weight: bold;
    padding-bottom: 2rem;
  }

  .buttons{
    display: flex;
    justify-content: center;
  }

  .params {
    display: flex; 
    flex-direction: column;
    flex-flow: column wrap;
    width: 96vw;
    font-size: 2rem;
  }

  .params h1{
    text-align: center;
  }

  .oneParam {
    display: flex; 
    flex-direction: column;
    flex-flow: column wrap;
    width: 96vw;
    border: 0.2rem rgb(192, 189, 189) solid;
    border-radius: 0.5rem;
    background-color: rgb(231, 225, 225);
    padding-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .oneParam h2{
    text-align: center;
    
  }
    
  .form-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 90vw;
    padding: 2rem;
    border-radius: 0.5rem;
    list-style-type: none;
    
  }

  .form-cont li {
    display: flex;
    min-width: 100%;
    flex-direction: column;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .form-cont h2 {
    text-align: center;
    display: flex;
    min-width: 100%;
    flex-direction: column;
    margin-top: 1rem;
  }
  
  .form-element{
    display: flex;
    flex-direction: column;
    min-width: 44vw;
    padding: 0.5rem;
    margin-left: 0.5em;
    margin-right: 0.5em;
    border: 0.1rem solid #ffffff ;
    background-color: #f0ecec;
    border-radius: 0.5rem;
    list-style-type: none;
  }

  .form-element-title{
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: row;
    padding-left: 2rem;
    
       
  }

  .form-element-title1{
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin-right: 2rem;
  }

  .form-element-title2{
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }

  .form-param{
    display: flex;
    flex-direction: row;

    padding: 0.5rem;
  }

  .form-range{
    display: flex;
    min-width: 25vw;
    align-items: center;
    margin-left: 3vw;
    
  }
 
/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ EREDMENY +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

.eredmenyContainer {
  order: 3;
  display: -webkit-flex; 
  min-width: 100%;
  min-height: 50%;
  padding-left: 1%;
  padding-right: 2%;
  padding-top: 1%;

}

.eredmenyParam {
  display: flex; 
  flex-direction: column;
  flex-flow: column wrap;
  width: 96vw;
  border: 0.2rem rgb(192, 189, 189) solid;
  border-radius: 0.5rem;
  background-color: rgb(231, 225, 225);
  padding-top: 2rem;
  margin-bottom: 2rem;
}
 
.eredmenyElement{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  min-width: 44vw;
  margin: 1rem;
  border: 0.1rem solid #ffffff ;
  background-color: #f0ecec;
  border-radius: 0.5rem;
  list-style-type: none;
}

.eredmenyElementMegallapit{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  min-width: 44vw;
  margin: 1rem;
  border: 0.1rem solid #ffffff ;
  background-color: #f0ecec;
  border-radius: 0.5rem;
  list-style-type: none;
}

.eredmenyOneElement{
  margin-left: 2rem;
  width: 45rem;

}

.eredmenyOneElementSmall{
  margin-left: 2rem;
  width: 30rem;

}



 .folyamatKep {
  display: -webkit-flex; 
  justify-content: center;
  margin-left: 5%;
  }

  #step1 {
    display: -webkit-flex; 
    position: absolute;
    z-index: 4;
  }

 /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ MAGNIFIER +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
 /*------------------------------------------------------------------- KÉPEK ------------------------------------------------------------------- */

  .wrapper {
    display: -webkit-flex; width: 100%; height: 100%; position: relative; z-index: 3; justify-content: left;
  }

  .oszlop {
    display: -webkit-flex; width: 47%; height: 100%; position: relative; justify-content: left;
  }

   /*------------------------------------------------------------------- BAL OSZLOP ------------------------------------------------------------------- */
  
  .kepBal {
    display: -webkit-flex; width: 30%; height: 100%; position: relative; flex-direction: column; 
  }

  .nyilBal {
    display: -webkit-flex; width: 5%; height: 100%; position: relative; z-index: 3; flex-direction: column; 
  }

  .kepBalFelso {
    display: -webkit-flex; width: 100%; height: 100%; top: 25%; left: -1%; position: absolute; z-index: 3; overflow: hidden; justify-content: center;
  }

  .kepBal2 {
    display: -webkit-flex; width: 100%; height: 100%; top: 35%; left: -1%; position: absolute; z-index: 3; overflow: hidden; justify-content: center;
  }

  .nyilBalFelso {
    display: -webkit-flex; width: 360%; height: 130%; top: 430%; left: -1%; position: absolute; z-index: 5; overflow: hidden; justify-content: center;
  }

  .nyilBal2 {
    display: -webkit-flex; width: 100%; height: 100%; top: 170%;left: -1%; position: absolute; z-index: 3; overflow: hidden; justify-content: center;
  }

  .nyilBal3 {
    display: -webkit-flex; width: 100%; height: 100%; top: 100%;left: -1%; position: absolute; z-index: 3; overflow: hidden; justify-content: center;
  }

  /*------------------------------------------------------------------- KÖZÉP OSZLOP ------------------------------------------------------------------- */

  .kep {
    display: -webkit-flex; width: 100%; height: 100%; top: -1%; left: -1%; position: absolute; z-index: 3; overflow: hidden; justify-content: center;
  }

  .nyil {
    display: -webkit-flex; width: 100%; height: 100%; left: -1%; position: absolute; z-index: 3; overflow: hidden; justify-content: center;
  }

  .kepKozep {
    display: -webkit-flex; width: 30%; height: 100%; position: relative; flex-direction: column; 
  }

  /*------------------------------------------------------------------- JOBB OSZLOP ------------------------------------------------------------------- */

  .nyilJobb {
    display: -webkit-flex; width: 5%; height: 100%; position: relative; flex-direction: column; 
  }

  .kepJobb {
    display: -webkit-flex; width: 30%; height: 100%; position: relative; flex-direction: column; 
  }

  .nyilJobbFelso {
    display: -webkit-flex; width: 100%; height: 100%; top: 200%;left: -1%; position: absolute; z-index: 3; overflow: hidden; justify-content: center;
  }

  .nyilJobb2 {
    display: -webkit-flex; width: 100%; height: 100%; top: 280%;left: -1%; position: absolute; z-index: 3; overflow: hidden; justify-content: center;
  }

  .kepJobb3 {
    display: -webkit-flex; width: 100%; height: 100%; top: 35%; left: -1%; position: absolute; z-index: 3; overflow: hidden; justify-content: center;
  }