html {
  font-size: 62.5%; /* 16px * 62.5 = 10px = 1rem */
  box-sizing: border-box;
}
body {
  font: 1.6rem Helvetica;
  height: 100vh;
  margin: 0;
}
button {
  font-size: 1.6rem;
}
.full-width {
  width: 100%;
}
#root {
  height: 100%;
}

.appContainer {
  align-items: flex-start;
  width:100vw;
  height: 100vh;
  top: 0; left: 0; 
  display: -webkit-flex; 
  overflow: hidden;
}

.grid-container {
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr 5rem;
  height: 100%;
}

.header {
  background-color: #153a26;
  position: fixed;
  min-width: 100%;
  color: #ffffff;
  display: -webkit-flex; 
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;
  padding-right: 5rem;
  padding-left: 5rem;
  z-index: 200;
}

.headerText {
  color: #ffffff;
  display: flex;
  padding: 0.5rem;
  padding-left: 55%;
}

.headerLogo {
  width: 14rem;
  padding: 0.5rem;
}

.buttonBelepes {
  display: -webkit-flex; 
  width: 15%;
  position: absolute;
  z-index: 2;
  right: 25%;
  top: 30%;

}

.chooseButtons {
  display: -webkit-flex; 
  width: 15%;
  position: absolute;
  z-index: 2;
  right: 55%;
  top: 20%;

}



#img-container{
  max-width: 55%;
}

.flex {
  display: -webkit-flex; 
}

.loginForm {
  display: -webkit-flex; 
  width: 20%;
  position: absolute;
  z-index: 2;
  right: 20%;
  top: 20%;

}

#buttonBe {
  transition: transform .3s
  cubic-bezier(0, .52, 0, 1);

}

#buttonBe:hover {
  transform:  scale(1.1, 1.1);
  opacity: 1;

}





.button {
  padding: 1rem;
  border: 0.1rem #808080 solid;
  border-radius: 0.5rem;
  cursor: pointer;
}
.button:hover {
  border: 0.1rem #404040 solid;
}
.button.primary {
  background-color: #f0c040;
}
.button.secondary {
  background-color: #f0f0f0;
}
.text-center {
  text-align: center;
}


/* forms */

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.form-container {
  display: flex;
  flex-direction: column;
  width: 32rem;
  padding: 2rem;
  border: 0.1rem #c0c0c0 solid;
  border-radius: 0.5rem;
  list-style-type: none;
}
.form-container li {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
input {
  padding: 1rem;
  border: 0.1rem #c0c0c0 solid;
  border-radius: 0.5rem;
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ PDF +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

.pdfSize {
  width: 90vw;
  height: 90vh;


}

.pdfImage {
  position: absolute;
  max-height: 88vh;
  min-width: 43vw;
  top: 3vh;
  z-index: 1;
}

.pdfContext {
  position: relative;
  z-index: 100;
 top: 3vh;
  left: 12vw;
}

.pdfTable{
  display:flex;
  flex-direction: column;
  width: 86rem;
  height: 60rem;
}

.headFull{
  display:flex;
  flex-direction: column;
  width: 86rem;
  text-align: center;
}

.pdfTable h1{
  font-size: 1.6rem;
  font-weight: bold;
}

.pdfTable h2{
  font-size: 1.2rem;
  font-weight: bold;
}

.pdfTable h3{
  font-size: 1rem;
  font-weight: bold;
}

.pdfTable h4{
  font-size: 1rem;
}

.columnWrapper{
  display:flex;
  flex-direction: row;
  width: 86rem;
}

.headWrapper{
  display:flex;
  flex-direction: row;
  width: 86rem;
  background-color: #b8cf8d;
}

.row100{
  min-width: 86rem;
  max-width: 86rem;
  text-align: center;
  border: 0.5px solid;
}

.row50Title{
  max-width: 43rem;
  min-width: 43rem;
  text-align: left;
  padding-left: 2rem;
  padding-top: 0.5rem;
  border: 0.5px solid;
}

.row70Title{
  max-width: 60rem;
  min-width: 60rem;
  text-align: left;
  padding-left: 2rem;
  padding-top: 0.2rem;
  border: 0.5px solid;
}

.row30Title{
  max-width: 26rem;
  min-width: 26rem;
  text-align: center;
  padding-top: 0.2rem;
  border: 0.5px solid;
}

.row15Title{
  max-width: 13rem;
  min-width: 13rem;
  text-align: center;
  padding-top: 0.2rem;
  border: 0.5px solid;
}

.row15Data{
  max-width: 13rem;
  min-width: 13rem;
  text-align: center;
  padding-top: 0.2rem;
  border: 0.5px solid;
}

.row50Data{
  max-width: 43rem;
  min-width: 43rem;
  text-align: center;
  padding-top: 0.5rem;
  border: 0.5px solid;
}



#pdfButton {
  display:flex;
  position: absolute;
  z-index: 100;
  top: 2rem;
  left: 100rem;
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ TABLE +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

.table1{
  width: 45vw;
}

.table1 h1{
  font-size: 1.7rem;
  font-weight: bold;
  text-align: center;  
}

.table1 h2{
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;  
}

.table1 h3{
  font-size: 1.1rem;
  font-weight: bold;
  text-align: left;  
}

.table1 h4{
  font-size: 1.1rem;
  font-weight: normal;
  text-align: left;  
}

.tableHead{
  background-color: #b0c78b;
}

.table th {
  text-align: center;   
}

td { 
  padding-left: 10px;
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ ADMIN +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

.admin {
  margin-top: 7rem;
  display: flex; 
  flex-direction: row;
  position: absolute;
  z-index: 2;

}

.adminTable {
  display: flex; 
  flex-direction: column;
  z-index: 2;

}
.pdf{
  display:flex;
  flex-direction: column;
}

.pdfUpload {
  display: flex; 
  flex-direction: column;
  padding: 2rem;
  border: 0.2rem, solid;
  
  border-radius: 2rem;
  z-index: 2;

}

.adminRow{
  background-color: 	 #d9d9d9;
  width: 36vw;
  margin: 1rem;
  padding: 1rem;
  border-collapse: collapse;
  border-spacing: 1rem;
  justify-content: space-around;
}

#adminUser{
  width: 20vw;

}

#adminRole{
  width: 10vw;
  
}

.buttonVisible{
  opacity: 1;
}

.buttonInvisible{
  opacity: 0;
}

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ POPUP +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

  .choosePopupContainer {
    display: flex; 
    justify-content: center;
    width: 100%;
  }

  .choosePopupImg {
    position: fixed;
   z-index: 1;
  }

  .choosePopupButton {
    position: absolute;
    left: -6vw;
    top: 1.2vh;
    z-index: 100;
  }

  .choosePopupText {
    position: relative;
    top: 12vh;
    left: -1vw;
    z-index: 1060;
  }

  .popupContainer {
    display:flex;
    position: absolute;
    z-index: 2000;
    padding-left: 90rem;
    padding-top: 10rem;
  }

  .popup {
    display:flex;
    position: absolute;
   
  }

  .inPopup {
    display:flex;
    position: absolute;
    width:50vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    z-index: 2100;
  }

  .inPopup h1{

    width:40vw;
    height: 26vh;
    margin-left: 3rem;
    margin-top: 5rem;
    overflow: auto;
    text-align: center;
    justify-content: center;
  }

  #popupButton{
    display: flex;
    margin-left: -50rem;
    margin-top: 2rem;
  }

  #popupLoginButton{
    display: flex;
    position: absolute;

    margin-top: -10rem;
    margin-left: 6rem;
   
  }

  .popupImg {
    width: 50vw;
    content:url("./assets/popup.png")
  }
  
  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ TUDASBAZISSCREEN +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

  .tudasbazisContainer {
    display: flex; 
    align-items: flex-start;
    flex-direction: row;
    position:absolute;
    width: 100%;
    height: 100%;
    display: -webkit-flex; 
    overflow: auto;
    z-index: 1;
  }

  .uploadContainer {
    display: flex; 
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    width: 46rem;
    height: 30rem;
    margin-left: 4rem;
    margin-top: 8rem;
    display: -webkit-flex; 
    overflow: hidden;
    z-index: 30;
  }

  .pdfListContainer {
    display: flex; 
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    width: 50rem;
    height: 45rem;
    margin-left: 50rem;
    margin-top: 16rem;
    display: -webkit-flex; 
    overflow: hidden;
    z-index: 30;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .pdfListContainer h1{ font-weight: bold; }

  .searchContainer {
    display: flex; 
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    width: 45rem;
    height: 65rem;
   
    margin-left: 105rem;
    margin-top: 8rem;
    display: -webkit-flex; 
    overflow: hidden;
    z-index: 30;
    font-size: 1.5rem;
  }

  .searchBar {
    display: flex; 
    width: 40rem;
    margin-top: 1rem;
  
    margin-left: 1rem;

  }

  
  #contentPic{ width: 2vw; }

  #contentPicture{ width: 1.2vw; }

  .imgContainer {
    display: flex; 
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 20;
  }
  
 
  .pdfUpload {
    display: flex; 
    flex-direction: column;
    padding: 2rem;
    width: 45rem;
    border: 0.2rem, solid;
    
    border-radius: 2rem;
    z-index: 2;
  
  }

.contentTable {
  display: flex; 
  flex-direction: column;
  width: 48rem;
  height: 65rem;
  margin-left: 2rem;
  overflow-y: visible;
  overflow-x: hidden;
  z-index: 2;

}

.contentTable h2:hover{
  font-weight: bold;
  color:#009933;
  cursor: pointer;

}

.searchContainer h1{ font-weight: bold; }

.contentRow{
  width: 26vw;
  padding: .3rem;
  border-collapse: collapse;
  justify-content: space-around;
}

.custom-file-input { width: 48rem; }

.input-group-text{ cursor: pointer; }


